<!--
--------------------------------------------------------------------------------
<copyright file="TechnologyLaserAvailableVsUsedTimes.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <p class="text">
      {{ $t('technology_console.widget_info_text.laser_available_vs_used_times_txt') }}
    </p>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class TechnologyLaserAvailableVsUsedTimesInfo extends Vue {}
</script>
